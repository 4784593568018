<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">视频列表</div>
        <el-button type="primary" @click="createUser" class="cardTitleBtn">添加视频</el-button>
      </el-col>
      <el-col :span="24">
         <el-table :data="videoList" style="width: 100%">
                <el-table-column prop="title" label="标题" sortable></el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="getDetail(scope.row)"
                      type="primary"
                      size="mini"
                    >
                      编辑
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      videoList:[],
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getVideoList()
  },
  methods: {
    // 获取视频列表
    getVideoList() {
      let self = this;
      self.$axios
        .get("/api/help-video/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.videoList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "视频列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    // 编辑
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "helpCenterVideoDetail", query: { guid: row.guid } });
    },
    // 新建
    createUser() {
      let self = this;
      self.$router.push({ name: "helpCenterVideoDetail", query: { guid: null } });
    }
  },
};
</script>
